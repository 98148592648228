
@font-face {
  font-family: Outfit;
  src: url("/public/Assets/fonts/Outfit-Regular.ttf");
}

.banner .carousel-caption>div:nth-of-type(1) {
  font-size: 27px !important;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Outfit;
  color: #2a2e32;
  text-align: center;
  /* margin-bottom: 10px; */
  /* display: inline-block; */
}

.banner .slide {
  width: 100%;
  border: 0 !important;
  padding: 0 !important;
}

.carousel-inner {
  padding: 0 !important;
}

/* .banner .carousel-inner{
  padding: 0px !important;
} */
.banner .slide:hover {
  border: 0px !important;
  box-shadow: none !important;
}

.banner .carousel-caption>div:nth-of-type(2) {
  font-size: 38px !important;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Outfit;
  color: #2a2e32;
  text-align: center;
  /* margin-bottom: 10px; */
  /* display: inline-block; */
}

.banner .carousel-caption>div:nth-of-type(3) {
  font-size: 45px !important;
  font-weight: 900 !important;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  /* display: inline-block; */
  font-family: Outfit;
  color: #2a2e32;
  text-align: center;
}

.banner .carousel-caption {
  /* padding: 0px !important; */
  position: relative;
  right: 0 !important;
  left: 0 !important;
  /* color: black !important; */
  text-align: center !important;
  top: 3%;

}


/* Responsive adjustments */
@media (max-width:1024px) {
  .banner .carousel-caption>div:nth-of-type(1) {
    font-size: 21px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(3) {
    font-size: 38px !important;
  }

  .banner .carousel-caption>div:nth-of-type(2) {
    font-size: 30px !important;
    /* margin-bottom: 5px; */
  }

  .shop-now {
    /* margin-top: 10px; */
  }

  /* .banner .carousel-caption div {
    line-height: 5.6vh;
  } */

}

@media (max-width: 768px) {
  .banner .carousel-caption {
    font-size: 10px !important;
    padding: 1rem;
    bottom: 2rem !important;
  }

  /* .banner .carousel-item .carousel-item-image {
    width: 100%;
    height: 50vh;
    background-size: cover;
  } */

}


.banner .carousel-indicators {
  position: absolute !important;
  /* bottom: 16px !important;
    width: 100% !important;
    text-align: center !important; 
    margin-left: 0px !important;
    z-index: 15 !important; Ensure it's above the slides */
  left: 50% !important;
  transform: translateX(-50%);
  /* bottom: 20px; */
  margin-left: 0 !important;
  /* margin: 0px !important; */
}

.banner .carousel-indicators button {
  width: 10px !important;
  height: 10px !important;
  margin: 0 6px !important;
  border: none !important;
  border-radius: 50% !important;
  background-color: #C0C0C0 !important;
  /* Grey color for inactive indicators */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important; */
  border: 2px transparent solid !important;
 
}
 
.banner .carousel-indicators .active {
  background-color: #88b04b !important;
  border: 2px solid rgba(136, 176, 75, 0.3) !important;
  /* margin-top: 5px !important; */
}

.shop-now {
  /* width: 100%; */
  /* position: absolute; */
  background-color: #ff6b6b !important;
  margin-top: 30px;
  /* height: 50px; */

  font-size: 18px;
  color: #fff !important;
  font-family: Outfit;
}

.banner .carousel-item .carousel-item-image {
  width: 100%;
  height: 50vh;
  background-size: cover;
}

.banner .slide {
  margin: 0 !important;
}
.banner .container-fluid{
  overflow: hidden;
}
.banner .carousel-control-next,
.banner .carousel-control-prev {
  display: none;

}



@media (min-width: 767px) and (max-width: 992px) {
  .banner .carousel-caption {
    font-size: 10px !important;
  }

  .shop-now {
    margin-top: 30px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .banner .carousel-caption {
    font-size: 26px !important;
  }
}


@media (max-width: 520px) {
  .banner .carousel-caption>div:nth-of-type(1) {
    margin-top: 2rem !important;
    font-size: 15px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-item .carousel-item-image {
    width: 100% !important;
    height: 313px !important;
    /* background-size: cover !important; */
    background-size: 100% !important;

  }
}

@media (max-width:435px) {
  .banner .carousel-caption>div:nth-of-type(1) {
    margin-top: 2rem !important;
    font-size: 15px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(3) {
    font-size: 24px !important;
  }

  .banner .carousel-caption>div:nth-of-type(2) {
    font-size: 20px !important;
    /* margin-bottom: 5px; */
  }

  .banner .home-banner-shop-now button {
    font-size: 14px !important;
  }
  .banner .carousel-item .carousel-item-image {
    width: 100% !important;
    height: 270px !important;
    /* background-size: cover !important; */
    background-size: 100% !important;

  }


}

@media (max-width: 390px) {

  .banner .carousel-caption>div:nth-of-type(1) {
    font-size: 14px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(2) {
    font-size: 16px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(3) {
    font-size: 15px !important;
  }

  .banner .carousel-item .carousel-item-image {
    width: 100% !important;
    height: 270px !important;
    /* background-size: cover !important; */
    background-size: 100% !important;

  }

}

@media (max-width: 360px) {

  .banner .carousel-caption>div:nth-of-type(1) {
    font-size: 12px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(2) {
    font-size: 13px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(3) {
    font-size: 12px !important;
  }

  .banner .carousel-item .carousel-item-image {
    width: 100% !important;
    height: 250px !important;
    /* background-size: cover !important; */
    background-size: 100% !important;

  }
}

@media (max-width: 360px) {

  .banner .carousel-caption>div:nth-of-type(1) {
    font-size: 12px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(2) {
    font-size: 13px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(3) {
    font-size: 12px !important;
  }

  .banner .carousel-item .carousel-item-image {
    width: 100% !important;
    height: 250px !important;
    /* background-size: cover !important; */
    background-size: 100% !important;

  }
}

@media (max-width: 280px) {

  .banner .carousel-caption>div:nth-of-type(1) {
    font-size: 10px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(2) {
    font-size: 11px !important;
    /* margin-bottom: 5px; */
  }

  .banner .carousel-caption>div:nth-of-type(3) {
    font-size: 10px !important;
  }

  .banner .carousel-item .carousel-item-image {
    width: 100% !important;
    height: 210px !important;
    /* background-size: cover !important; */
    background-size: 100% !important;

  }
  .banner .home-banner-shop-now button {
    font-size: 10px !important;
  }
}