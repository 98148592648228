@font-face {
    font-family: Outfit;
    src: url("/public/Assets/fonts/Outfit-Regular.ttf");
}
.footer-size{
    font-size: 14px !important ;
    font-family: Outfit !important;
}
.footer-icon-size{
    font-size: 13px !important;
}
.footer a{
    color: black !important;
}
.Quick-Links {
    list-style: none !important;
}

.Quick-Links i {
    color: #FF6B6B !important ;
}

.Quick-Links li {
    margin-bottom: 10px !important;  
    /* Adjust as needed for spacing between list items */
}

.Quick-Links li:hover a {
    color: #FF6B6B !important;
    /* Replace 'your_custom_color' with the desired color code or name */
}

.Contact-Us {
    list-style: none !important;
}

.our-dedication-and {
    /* letter-spacing: 0.05em; */
    padding-right: 20px;
    font-family: Outfit !important;
    color: #2a2e32;
    text-align: left;
}


.footer-image img {
    width: 30%;
    height: 30%;
}

.daksh-india-all-container {
    font-size: 15px !important;
    /* letter-spacing: 0.05em; */
    color: #2a2e32;
    text-align: left;
    font-family: Outfit !important;
}
