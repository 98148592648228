@media screen and (max-width: 1200px) {

    /* .sidebar {
        max-width: 50%;
        max-height: 100%;
        margin-top: 60px;
        margin-left: 16px
    } */
    .layout-2 .sidebar.open {
        width: 200px;
        top: 60px;
        left: 14px;
    }
}