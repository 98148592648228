/* .App{
  margin:1rem;
}*/

.card-img-top{height:200px}
.details .fa-close{
  position: absolute;
  right: 15px;
  top: 10px;
  color: #6c757d;
  cursor: pointer;}