.img-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    z-index: 1;
}



.img-container .img-preview {
    width: 100%;
    height: auto;
    z-index: 2;
}



.img-container .img-button {
    position: absolute;
    top: 23px;
    right: -23px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    /* padding: -5px 81px; */
    border: 2px solid #fff;
    opacity: 0;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    z-index: 3;

}

/* .img-container .img-button {
    position: absolute;
    top: 23px;
    right: -29px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: -5px 81px;
    opacity: 0;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    z-index: 3;

} */



.img-container:hover .img-button {
    background-color: black;
    opacity: 1;
}