.img-select {
  /* Add any styling you need for the container */
}

.img-row {
  display: flex;
  flex-wrap: wrap;
  height: 140px;
  overflow: auto;
}

.img-item {
  box-sizing: border-box;
  padding: 20px;
  /* Adjust padding as needed */
  background-color: #f0f0f0;
  /* Adjust background color as needed */
  margin: 10px;
  /* Add margin for spacing between items */
  text-align: center;
  /* Center the content inside each item */
}

.img-item img {
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1 / 1;
  display: block;
  /* Remove extra space beneath image */
}

.st-inline-share-buttons .st-btn {
  display: inline-block !important;
  border: none !important;
}

.st-label {
  display: none !important;
}

.product-details-breadcrumb .breadcrumb-item a {
  font-size: 14px !important;
  letter-spacing: 0.05em;
  font-weight: 500;
  font-family: Outfit !important;
  color: #5b5b5b;
  text-align: left;
  display: flex;
  align-items: center;
}

.product-details-breadcrumb ol {
  background-color: white !important;
}

.product-details-img-main {
  width: 100%;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 367.69px;
  object-fit: cover;
}

.product-details-img {
  width: 100%;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 85.82px;
  object-fit: cover;
  
}

.product-details .product-name {
  font-size: 25px !important;
  letter-spacing: 0.05em;
  font-weight: 600;
  font-family: Outfit;
  color: #2a2e32;
  text-align: left;
  display: inline-block;
}

.product-details .product-code {
  font-size: 13px !important;
  letter-spacing: 0.05em;
  font-family: Outfit;
  color: #2a2e32;
  text-align: left;
  display: inline-block;
}

.product-details .discount {
  /* padding: 0px 16px 0px 4px; */
  background-color: #ff6b6b;
  letter-spacing: 0.07em !important;
  font-size: 11px !important;
  font-family: Outfit !important;
  color: #fff;
}

.price-before-discount{
    color:#9f9f9f;
}
.product-details .price {
  text-decoration-line: line-through;
  font-size: 14px !important;
  letter-spacing: 0.05em;
  font-family: Outfit !important;
}

.product-details .discounted-price {
  font-size: 14px !important;
  letter-spacing: 0.05em;
  font-family: Outfit !important;
  color: #88b04b;
}

.product-details .per-peice {
  font-size: 12px !important;
  font-weight: 500;
  font-family: Outfit !important;
  color: #5f5f5f;
}

.product-details .product-properties {
  font-size: 11px !important;
  letter-spacing: 0.05em;
  font-weight: 500;
  font-family: Outfit;
  color: #9f9f9f;
  text-align: left;
}

.product-details .product-properties-figure {
  font-size: 14px !important;
  letter-spacing: 0.05em;
  font-weight: 500;
  font-family: Outfit;
  color: #5b5b5b;
  text-align: left;
  display: inline-block;
}

.product-details .description-text p {
  font-size: 12px !important;
  letter-spacing: 0.05em;
  font-family: Outfit;
  color: #5b5b5b;
  text-align: left;
  /* display: inline-block; */
}

.product-details .description-text {
  line-height: normal;
}

.thumbnail:hover .thumbnail-image,
.selected .product-details-img {
  border: 2px solid #88B04B;
  padding: 2.5px;
}

.product-details .product-buttons .buy-now {
  border: 0px;
  border-radius: 2px;
  background-color: #88b04b;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-family: Outfit !important;
}

.product-details .product-buttons .product-add-to-cart {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  border-radius: 2px;
  background-color: #ff6b6b;
  color: white;
  box-sizing: border-box;
  font-family: Outfit !important;
}

.product-details .add-order-quantity {
  border-radius: 2px;
  background-color: #f8f7f7;
  border: 0.5px solid #dadada;
  box-sizing: border-box;
  font-family: Outfit !important;
  color: #5b5b5b !important;
  padding: 6px 10px;
}

.product-details .add-order-quantity i {
  font-size: 13px !important;
}

.product-details .add-order-quantity .product-min-order-quant {
  font-family: Outfit;
  color: #5b5b5b;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
}

@media (max-width: 768px) {
  .product-details .product-add-to-cart,
  .buy-now {
    padding: 7px 4px !important;
    font-size: 15px !important;
  }
  .product-details .product-name {
    font-size: 20px !important;
  }
  .product-details .product-properties-figure {
    font-size: 13px !important;
  }
}
@media (max-width: 430px) {
  .product-details .product-add-to-cart,
  .buy-now {
    padding: 8px 8px !important;
    font-size: 11px !important;
  }
  .product-details .add-order-quantity .product-min-order-quant,
  .product-details .add-order-quantity i {
    font-size: 13px !important;
  }
  .product-details .add-order-quantity {
    padding: 4px 10px;
  }
  .product-details .add-order-quantity .pe-4 {
    padding-right: 1rem !important;
  }
  .product-details .product-details-img-main {
    padding-bottom: 18px;
  }
}
