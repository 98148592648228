.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.form-check-input {
  width: 1em;
  height: 1em !important;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  -webkit-print-print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.cart .card form input[type="text"],
.cart .card form input[type="number"],
.cart .card form input[type="date"],
.cart .card form input[type="email"],
.cart .card form textarea,
.cart .card form select {
  border-radius: 14px !important;
  height: 48px;
  border-color: #007185;
}

.fa-check-circle:before,
.fa-circle-check:before {
  content: "\f058";
}

*,
::after,
::before {
  box-sizing: border-box;
}

#successmsg i {
  font-size: 5rem !important;
  margin-bottom: 20px;
}

#successmsg h4,
#successmsg i {
  color: #20b004;
  font-size: 40px;
}

.btn-default.total-price-card.place-order,
.bg-success.total-price-card {
  color: #000;
  background-color: #e5e5e5 !important;
  border-color: #e5e5e5 !important;
}

.border-danger {
  border-width: medium;
}

.custom-button {
  width: 80px !important;
  font-size: 13px !important;
  background-color: white !important;
  color: blue;
}

.cart .cart-img {
  width: 100%;
  height: 100px;
}

.cart .cart-product-detail {
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #ebebeb;
  padding: 15px;
  text-align: left;
  font-size: 22px;
  color: #2a2e32 !important;
  font-family: Outfit !important;
}

.cart .cart-product-name {
  font-size: 16px !important;
  letter-spacing: 0.05em;
  font-weight: 600 !important;
  font-family: Outfit !important;
  color: #2a2e32;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cart .cart-product-material {
  margin: 0 !important;
  font-size: 13px !important;
  letter-spacing: 0.05em;
  font-weight: 500;
  font-family: Outfit !important;
  color: #5b5b5b;
  text-align: left;
}

.cart .cart-product-discount {
  padding: 2px 7px !important;
  background-color: #ff6b6b;
  letter-spacing: 0.07em !important;
  font-size: 10px !important;
  font-family: Outfit !important;
  color: #fff;
}

.cart .cart-product-price {
  text-decoration-line: line-through;
  font-size: 12px !important;
  letter-spacing: 0.05em;
  font-family: Outfit !important;
}

.cart .cart-product-discounted-price {
  font-size: 14px !important;
  letter-spacing: 0.05em;
  font-family: Outfit !important;
  color: #88b04b;
}

.cart .cart-product-per-peice {
  font-size: 12px !important;
  font-weight: 500;
  font-family: Outfit !important;
  color: #5f5f5f;
}
.cart .cart-product-order-quantity{
  border-radius: 2px;
  background-color: #f8f7f7;
  border: 0.5px solid #dadada;
  box-sizing: border-box;
  font-family: Outfit !important;
  color: #5b5b5b !important;
  padding: 0px 7px;
}

.cart .cart-product-order-quantity i {
  font-size: 10px !important;
}

.cart .cart-product-order-quantity .cart-product-min-order-quant {
  font-family: Outfit;
  color: #5b5b5b;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
}
.cart .cart-product-delete-icon i{
  color: #5B5B5B;
}
.confirm-btn{
  background-color: #ff6b6b !important;
  color: #fff !important;
}