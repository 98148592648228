.slidedown{
 top: 94px !important;
}
.header-search-suggestion .No-Product{
    font-family: Outfit !important;
    color: black !important;
    font-size: 20px !important;
    font-weight: 600 ;
}

.header-search-suggestion {
    background-color: white;
    position: absolute;
    z-index: 99;
    top: 44px;
    color: #9f9f9f;
    font-family: Outfit !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    text-align: left;
    width: 100%;
}
.search-suggestion-products{
    height: 230px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.search-suggestion-products::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
}

.search-suggestion-products::-webkit-scrollbar {
    width: 18px;
    background-color: transparent;
}

.search-suggestion-products::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
    background-color: #FF6B6B;
}

.search-suggestion-products::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
}

.search-suggestion-products::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
}

.search-suggestion-products::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
}

.search-suggestion-heading {
    font-size: 14px !important;
    letter-spacing: 0.05em;
    font-family: Outfit !important;
}

.header-search-suggestion .line-div {
    width: 100%;
    position: relative;
    border-top: 1px solid #cbcbcb;
    box-sizing: border-box;
    height: 1px;
    margin: 0.5rem 0 ;
}

.header-search-suggestion img {
    width: 100%;
    height: 62px;
}

.header-search-suggestion .product-name {
    font-size: 13px !important;
    letter-spacing: 0.05em;
    font-weight: 600 !important;
    font-family: Outfit !important;
    color: #2a2e32;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}

.header-search-suggestion .discount {
    padding: 0px 16px 0px 4px;
    background-color: #ff6b6b;
    letter-spacing: 0.05em !important;
    font-size: 10px !important;
    letter-spacing: 0.05em;
    font-weight: 500 !important;
    font-family: Outfit !important;
    color: #fff;
}

.header-search-suggestion .price {
    text-decoration-line: line-through;
    font-size: 13px !important;
    letter-spacing: 0.05em;
    font-family: Outfit !important;
    color: #9f9f9f;
}

.header-search-suggestion .discounted-price {
    font-size: 13px !important;
    letter-spacing: 0.05em;
    font-family: Outfit !important;
    color: #88B04B;
}

.header-search-suggestion .per-peice {
    font-size: 11px !important;
    font-weight: 500;
    font-family: Outfit !important;
    color: #5f5f5f;
}
.header-search-suggestion hr{
    margin: 0.5rem 0 !important;
}
.search-suggestion-bottom-heading{
    font-size: 14px !important;
    letter-spacing: 0.05em;
    font-family: Outfit !important;
    color: #ff6b6b;
    font-weight: 500 !important;
    cursor: pointer;
}


/* MEDIA QUERIES */
@media (max-width : 430px){

    .header-search-suggestion .product-name {
        font-size: 10px !important;
        letter-spacing: 0.05em;
        font-weight: 600 !important;
        font-family: Outfit !important;
        color: #2a2e32;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
    }
}

@media (max-width:768px){
   .header-search-suggestion .product-discount{
    padding-right: 28px !important;
   }
}