@font-face {
    font-family: Outfit;
    src: url(/public/Assets/fonts/Outfit-Regular.ttf);
}

.header {
    font-family: Outfit !important;
}

.Discount-Header {
    background-color: #88b04b;
    color: white;

}

.Discount-Text marquee{
    font-size: 13px !important;
    padding: 0px;
    margin: 0px;
}

.header-img img {
    width: 65%;
    /* height: 5vh; */
}

.header-img {
    width: 200px;
}

.header-input {
    width: 500px;
    position: relative;
}

.header-input .input-group {
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #b5b2b3;
}


.header-search-button,
.header-search-button2 {
    border-radius: 0px 2px 2px 0px !important;
    background-color: #ff6b6b !important;
}

.header-search-button i {
    color: white;
}

.header-search-button2 i {
    color: white;
}

.gurugram {
    font-weight: 600;
    font-size: 12px !important;
    letter-spacing: 0.05em;
    font-family: Outfit;
    color: #2a2e32;
    text-align: left;
}

.wholesaler {
    font-size: 16px !important;
    letter-spacing: 0.05em;
    font-weight: 600;
    font-family: Outfit;
    color: #2a2e32;
    text-align: left;

}

.header-user-icon {
    display: none;
    font-size: 23px !important;
}

.header-dropdown {
    font-size: 13px !important;
    font-weight: 600 !important;
    letter-spacing: 0.05em !important;
    border-left: 1px solid #b5b2b3 !important;
    font-family: Outfit !important;
    color: #2a2e32 !important;
    text-align: left !important;
}

.header .dropdown button {
    background-color: white !important;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
}

.header .dropdown-menu.show {
    width: 100% !important;
}

.dropdown-menu .dropdown-item:hover{
    color: #ff6b6b !important;
}


.header-inputbox::placeholder {
    font-size: 13px !important;
    letter-spacing: 0.05em !important;
    font-weight: 600 !important;
    font-family: Outfit !important;
    color: #888 !important;
    text-align: left !important;
}

.header-inputbox2::placeholder {
    font-size: 13px !important;
    letter-spacing: 0.05em !important;
    font-weight: 600 !important;
    font-family: Outfit !important;
    color: #888 !important;
    text-align: left !important;
    /* display: none !important; */
}

.header-inputbox-2 {
    display: none !important
}

.search-icon {
    display: none !important;
}

.login-signup {
    font-size: 13px !important;
    letter-spacing: 0.05em;
    font-weight: 600;
    font-family: Outfit;
    color: #2a2e32;
    text-align: left;
}

.my-account {
    font-size: 13px !important;
    letter-spacing: 0.05em;
    font-weight: 600;
    font-family: Outfit;
    color: #2a2e32;
    text-align: left;
}

.header-login {
    cursor: pointer;
}

.header-cart-image {
    position: relative;
}

.header-cart-icon {
    position: relative;
}

.header-cart-item {
    position: absolute;
    /* padding: 3px; */
    font-family: Outfit !important;
    background-color: #ff6b6b;
    color: white;
    font-weight: 500 !important;
    border-radius: 50%;
    font-size: 10px !important;
    top: -5px;
    right: 2.3%;

}

.header-cart-text {
    font-family: Outfit !important;
    font-size: 15px !important;
    color: black !important;
    font-weight: 500 !important;
}

.dropdown-item {
    font-family: Outfit !important;
    font-size: 15px !important;

}


@media (max-width: 1024px) {

    .gurugram,
    .my-account,
    .login-signup,
    .header-dropdown {

        font-size: 11px !important;

    }

    .wholesaler {
        font-size: 15px !important;


    }
}

@media (max-width: 430px) {
    .header-input {
        flex: 1 !important;
    }

    .gurugram-wholesaler,
    .header-input .form-control,
    .header-input .header-dropdown,
    .vr,
    .header-search-button {
        display: none !important;
    }

    .header-input .header-search-suggestion {
        display: none;
    }

    .header-input .input-group {
        border: none;
    }

    .search-icon,
    .header-search-button2 {
        display: block !important;
    }

    .header-inputbox-2 {
        display: flex !important;
        position: relative;
    }

    .header-input.active .form-control {
        display: block !important;
    }

    .header-input.active .btn.header-search-button {
        display: none !important;
    }

    .header-input .search-icon {
        font-size: 18px !important;
    }

}

@media (max-width:768px) {

    .header-cart-text,
    .gurugram-wholesaler,
    .header-dropdown,
    .login-signup,
    .my-account {
        display: none !important;
    }

    .header-user-icon {
        display: block;
        font-size: 20px !important;
    }

    .header-login {
        margin-left: 4px;
        margin-right: 4px;
    }

    .header-cart-image {
        width: 25px;
    }

    .header-img.text-center {
        text-align: left !important;
    }

}