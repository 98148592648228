@font-face {
    font-family: Poppins;
    src: url(../fonts/Poppins-Regular.ttf);
}

* {
    font-family: Poppins;
    font-size: 16px;
}

.text-yellow {
    color: #ffbf00;
}

/* body {
    margin: 1rem;
} */
.layout-2 .sidebar .menu-list .m-link.active .fill-secondary, .layout-2 .sidebar .menu-list .m-link:hover .fill-secondary {
    fill: #00ac9a !important;
}

body#layout-1 {
    margin: 0rem;
}

.form-control:hover, .form-select:hover {
    border-color: #007185;
}

.wrapper .page-body.auth {
    background: var(--body-color);
}

.auth form .btn-primary {
    padding: 10px 20px;
    background-color: #007185;
    border-color: #007185;
}

.layout-2 .sidebar.dark,
.layout-2 .sidebar.dark.sidebar-mini {
    background: var(--green-color);
    border-radius: 0.5rem;
    overflow-y: hidden;
    box-shadow: none;
}

.layout-2 .sidebar.sidebar-mini .sidebar-title .sm-txt {
    background: #ffffff;
    color: var(--green-color);
    display: inline-flex !important;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
}


.layout-2 .sidebar.dark .menu-list .m-link {
    border-radius: 15px;
    color: #ffffff;
}

.layout-2 .sidebar.dark .menu-list .m-link span {
    font-size: 15px;
}

.layout-2 .sidebar.dark .menu-list .m-link.active,
.layout-2 .sidebar.dark .menu-list .m-link:hover {
    color: #007185;
}

.layout-2 .wrapper {
    overflow-x: hidden;
    box-shadow: none;
}

.layout-2 .wrapper::-webkit-scrollbar {
    background: transparent;
    visibility: hidden;
    width: 0px;
}

.layout-2 .wrapper .page-header {
    background: #ffffff;
    border-radius: 0.5rem;
    border-bottom: 0px;
    /* box-shadow: 0px 3px 10px -3px #888888; */
}

:root [data-luno=theme-blue] {
    --body-color: #f1f1f1;
    /* --green-color: #007355; */
    --green-color: #131921;
    --primary-gradient: #ffffff;
}

.layout-2 {
    background: var(--body-color);
}

.form-control {
    border-radius: 0.5rem;
}

.avatar {
    width: 2rem;
    height: 2rem;
}

.user-profile p {
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 0.5rem;
    color: #007185;
}

.user-profile small {

    color: #CBCBCB;
}

.icon-bell {
    font-style: normal;
    font-weight: 800;
    font-variant: normal;
}

.card {
    border-radius: 0.5rem;
    /* border-style: none; */
}

.btn {
    border-radius: 14px;
}

.btn-warning {
    border: 1px solid #ffbf00;
    background: #ffbf00;
    border-radius: 14px;
    padding: 8px 20px;
}

.btn-warning:hover {
    border: 1px solid #ffbf00;
    background: #ffbf00;
    border-radius: 15px;

}

.btn-outline-warning {
    border: 1px solid #ffbf00;
    border-radius: 14px;
    padding: 8px 20px;
}

.btn-outline-warning:hover {
    border: 1px solid #ffbf00;
    background-color: #ffbf00;
}

.btn-outline-warning p {
    color: black;
    font-weight: bolder;
}

.table tr th {
    text-transform: capitalize;
    font-size: 12px;
}

.card .card-footer {
    border-top: none;
}

input::placeholder {
    font-size: 14px;
}

.btn-success {
    background-color: #007185;
    border: 1px solid #007185;
    border-radius: 15px;
    color: #ffffff;
    font-weight: bolder;

}

.btn.btn-dark {
    background-color: var(--green-color);
    border-color: var(--green-color);
    color: #ffffff;
}

.datepicker {
    padding: 0.375rem 0.75rem;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    border-radius: 0.5rem;
}

.btn.btn-light-success {
    background-color: rgb(255 191 0 / 15%);
    color: #ffbf00;
    border-color: transparent;
    padding: 6px 20px !important;
}

.btn.btn-light-success, .btn.btn-light-secondary {
    padding: 0.375rem 0.75rem;

}

.btn.btn-light-success:hover {
    background-color: #ffbf00;
    color: #000;

}

.btn.edit-sm {
    background-color: #888888;
    border-color: #888888;
    color: #f1f1f1;
}

.btn.edit-sm:hover {
    background-color: #373737;
    border-color: #373737;
    color: #f1f1f1;
}

.col-sm-2, .col-sm-3 {
    margin-top: 1rem;
}

.multi-select-drop {
    position: relative;
}

.multi-select-drop i {
    position: absolute;
    top: 48px;
    right: 25px;
    font-weight: 500;
    font-size: 19px;
    z-index: 1;
}

.main-border {
    border: 1px dashed rgba(25, 26, 28, 0.125);
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
}

.main-border-dashboard .card-body {
    border: 1px dashed rgba(25, 26, 28, 0.125);
    border-bottom: 0;
    border-radius: 14px;
    padding: 20px;
    position: relative;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.main-border .fa-close {
    top: 10px;
    position: absolute;
    right: 10px;
    color: red;
    cursor: pointer;
}

.product-imgaes-list img {
    width: 100%;
}

.main-card {
    border: 1px dashed #191a1c20;
    padding: 30px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    color: #007355;
    transition: box-shadow .25s ease, transform .25s ease;
    cursor: pointer;
}

.main-card:hover {
    box-shadow: 0 6px 1rem rgba(25, 24, 24, .1), 0 .5rem 1rem -.75rem rgba(25, 24, 24, .1) !important;
    /* transform: translate3d(0,-3px,0); */
}

.add-row {
    float: right;
}

.city-name label {
    font-size: 14px;
    color: #373737;
    padding-bottom: 5px;
}

.links-name {
    border: 1px dashed #191a1c20;
    border-radius: 7px;
    margin-top: 20px;
    position: relative;
    padding: 20px;
}

.links-name .fa-close {
    position: absolute;
    right: 10px;
    top: 10px;
}

.links-name textarea {
    border-radius: 8px;
}

table.card-table td,
table.card-table th {
    border-right: 1px dashed #e9e6e1 !important;
}

table select.form-select,
table input.form-control {
    border: 0;
}

table select,
table input {
    background-color: transparent;
    box-shadow: none;
}

table .input-group span {
    background-color: #fff;
    border: 0;
}

table thead th.wholesale {
    background-color: #dcf6f0;
}

table thead th.nursery {
    background-color: #f8f2dc;
}

table thead th.godown {
    background-color: #f3f9d8;
}

.product-para ul li {
    color: #a4a4a4;
}

.product-para ul li strong {
    color: #a4a4a4;
    font-weight: 500;
}

table a {
    color: #007185;
}

.fa-minus.text-primary,
.fa-plus.text-primary {
    color: #007185 !important;
}

/* .fa-plus.text-primary{
    display: none;
}
.fa-plus.text-primary.show{
    display: block;
}
.fa-minus.text-primary.hide{
    display: none;
} */
.citiwise-table .input-group input {
    text-align: right;
}

.admin-price-card {
    background-color: #ff8f00 !important;
    border-color: #ff8f00 !important;
}

.admin-price-card .fa-circle {
    display: none;
}

.admin-price-card span.price {
    position: absolute;
    right: 1.2rem;
}

@media (max-width: 768px) {

    .wrapper .page-body.auth,
    .layout-2 .wrapper {
        height: 100%;
    }

    .row-title {
        flex-direction: inherit !important;
        text-align: inherit !important;
    }

    .product-para p {
        margin-top: 10px !important;
    }

    .product-para p:first-child {
        padding-top: 0 !important;
        margin-top: 5px !important;
    }

    .product-cost,
    .product-cost .row {
        padding-bottom: 0 !important;
    }
}

.dropify-wrapper .dropify-message p {
    font-size: 13px;
}

.btn-copy {
    border-radius: 0px 0px 15px 15px;
}

:root {
    --bs-warning: #ffbf00;
}

[data-luno=theme-blue] {
    --primary-color: #131921;
    --secondary-color: #ffbf00;
}

.layout-2 .sidebar .menu-list>li {
    margin-bottom: 16px;
}

.dropify-wrapper {
    border-radius: 1.05rem !important;
    border: 1px dashed #E5E5E5 !important;
    margin-top: 5px;
}

.citiwise-table select::-ms-expand {
    display: none;
}

.card-body1 textarea {
    border-radius: 5px;
}

.quantity-button input {
    margin-bottom: 0 !important;
    height: 40px;
    margin-right: 0 !important;
    border: 1px solid #007185 !important;
    border-radius: 14px !important;
    border-right: 0;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 0.45rem 0.6rem;
    z-index: auto !important;
}

.quantity-button span {
    background-color: #007185;
    border: 1px solid #007185 !important;
    padding: 0.375rem .75rem;
    border-top-right-radius: 14px !important;
    border-bottom-right-radius: 14px !important;
}

.quantity-button span i {
    color: #fff;
}

.card .card-product .quantity-button {

    position: absolute;
    right: 15px;
    bottom: 0px;
}

.card .card-product .quantity-button input {
    width: 60px;

}

.card .card-product {
    border: 1px solid #007185;
}

.card .card-product .text-success {
    color: #007185 !important;
}

.total-price-card {
    color: #fff;
}

.total-price-card a {
    color: #fff;
}

.total-price-card .items {
    font-size: 14px;
    vertical-align: text-bottom;
}

.total-price-card {
    color: #000;
}

.total-price-card a {
    color: #000;
}

.total-price-card .fa-circle {
    font-size: 4px;
    position: relative;
    top: -4px;
}

.total-price-card .fa-chevron-right {
    font-size: 13px;
}

.total-price-card .request {
    float: right;
}

.dashboard-widgets .card {
    border: 1px dashed rgba(25, 26, 28, 0.125);
}

.dashboard-widgets .card .text-success {
    color: #007185 !important;
}

@media (max-width: 768px) {
    .citiwise-table .input-group {
        width: 6rem;
    }
}

@media (max-width: 480px) {
    .cart-row {
        padding: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .card.form-card {
        margin-top: 20px;
    }

}


.btn-primary {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #007355;
    --bs-btn-border-color: #007355;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #007355;
    --bs-btn-hover-border-color: #007355;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #131921;
    --bs-btn-active-border-color: #131921;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(25, 26, 28, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #007355;
    --bs-btn-disabled-border-color: #007355;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
}

.btn-outline-warning {
    --bs-btn-color: #ffbf00;
    --bs-btn-border-color: #ffbf00;
    --bs-btn-hover-color: #191A1C;
    --bs-btn-hover-bg: #ffbf00;
    --bs-btn-hover-border-color: #ffbf00;
    --bs-btn-focus-shadow-rgb: 241, 223, 60;
    --bs-btn-active-color: #191A1C;
    --bs-btn-active-bg: #ffbf00;
    --bs-btn-active-border-color: #ffbf00;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(25, 26, 28, 0.125);
    --bs-btn-disabled-color: #ffbf00;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ffbf00;
    --bs-gradient: none
}

.row-title h6:hover a {
    color: #131921;
    font-weight: 700 !important;
}

@media (max-width: 1024px) {
    .card .card-product .details p {
        margin-left: 2rem;
    }
}

@media (max-width: 768px) {
    .card .card-product .details p {
        margin-left: 0rem;
    }
}

.product_container {
    background-color: red;
    position: relative;
    width: 100%;
    padding-top: 100%;
    /* 1:1 Aspect Ratio */
    border: 5px solid red;
}

.text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 20px;
    color: white;
}

.universal-search {
    width: 50%;
    float: right;
}

.header-left {
    position: relative;
}

.slidedown {
    position: absolute;
    z-index: 99;
    /* right: 0; */
    width: 50%;
}

.universal-card {
    border: 1px dashed #00000020;
}

.universal-card .divider {
    width: 1px;
    height: 30px;
    background-color: #ccc;
}

.already-file .dropify-wrapper {
    background-color: transparent;
}

.already-file .dropify-message {
    display: none;
}

.transection-details .card {
    border: 1px dashed #e9e6e1;
}

.transection-details .card .card-footer {
    border-top: 1px dashed #e9e6e1;
}

.w125 {
    width: 125px;
}

.verify-mobile {
    position: relative;
}

.verify-mobile i {
    position: absolute;
    right: 1.5rem;
    bottom: 0.6rem;
    z-index: 9;
}

.text-teritary {
    color: #007185;
}

table tbody td {
    white-space: normal !important;
}

.border-left {
    border-left: 1px dashed #007185 !important;
}

.card-body.hide {
    display: none;
}

.admin-price-card.rfq-price-card {
    background-color: #CFE9DB !important;
    border-color: #CFE9DB !important;
}

.border-left {
    border-left: 1px dashed #007185 !important;
}

.fixed-dimensions {
    max-height: 98px !important;
    max-width: 98px !important;
}