.global-product-filter .filters-heading {
  font-size: 14px !important;
  font-family: Outfit !important;
  color: #2a2e32 !important;
  text-align: left;
  display: inline-block;
  font-weight: 500;
}
 
 
.global-product-filter .apply {
  color: #ff6b6b;
  font-family: Outfit;
  font-size: 12px !important;
}
 
.global-product-filter .clear {
  font-family: Outfit;
  font-size: 12px !important;
}
 
.global-product-filter .filters button:active {
  border: 0px !important;
}
 
.global-product-filter .dropdown-menu {
  position: relative !important;
  transform: none !important;
}
 
.global-product-filter .dropdown button {
  font-family: Outfit;
  padding: 0px;
  font-size: 14px !important;
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
 
.global-product-filter .dropdown button .fa-chevron-down {
  font-size: 11px !important;
}
 
.global-product-filter .dropdown .dropdown-item {
  font-family: Outfit;
  font-size: 12px !important;
  /* text-transform: capitalize !important; */
}
 
.global-product-filter .dropdown .price-slider div {
  font-family: Outfit;
  font-size: 14px !important;
}
 
.global-product-filter .rc-slider-track {
  background-color: #ff6b6b;
}
 
.global-product-filter .rc-slider-handle {
  border: 2px solid #ff6b6b;
}
 
.global-product-filter .rc-slider-handle:hover {
  background-color: #ff6b6b;
}
 
.global-product-filter .search-result-heading {
  color: black !important;
  font-size: 25px !important;
  font-family: Outfit;
  text-align: left;
  display: inline-block;
}
 
.global-product-filter .showing-results {
  color: black !important;
  font-size: 15px !important;
  font-family: Outfit;
  text-align: left;
  display: inline-block;
}
 
.product-filter-bottom-hr {
  color: #9c9a9a !important;
  height: 1px !important;
}
 
.accordion {
  border: none !important;
}
 
.form-check-input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem #facece !important;
 
}
 
.accordion-body-margin-top {
  margin-left: -20px !important;
  margin-top: -30px !important;
}
 
.filters-checkboxes {
  font-size: 13px !important;
  margin-top: 6px !important;
  color: #ff6b6b;
}
 
.filters-checkboxes:checked {
  background-color: #ff6b6b;
  border-color: #ff6b6b;
  box-shadow: #ff6b6b;
}
 
.filters-checkboxes-labels {
  font-family: Outfit;
  font-size: 12px !important;
  color: #5b5b5b !important;
}
 
.accordion__button {
  padding: 8px !important;
  font-size: 13px !important;
  background-color: white !important;
  color: #2a2e32 !important;
}
 
.acccordion-span-labels {
  font-size: 13px !important;
  font-family: "Outfit";
}
 
.accordion__button::before {
  display: none !important;
}
 
.accordion__button::after {
  display: none !important;
}
 
.filter-m {
  display: none;
}
 
.Mui-selected {
  background-color: #ff6b6b !important;
}
 
 
.filter-m {
  display: none !important;
}
 
.accordion__panel {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
  margin-bottom: -10px !important;
}
.global-product-filter .product-main-card{
  position: relative;
}
.global-product-filter .Product:hover{
  position: absolute;
    z-index: 999;
    /* height: 100%; */
    background-color: #fff;
    width: -webkit-fill-available;
}
 
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
 
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
 
.fade-slide-in {
  animation: slideInFromBottom 0.3s ease-out forwards;
}
 
@media only screen and (max-width: 780px) {
  .filter-m {
    display: block !important;
  }
 
  .filterer {
    display: none;
  }
 
  .modal-content {
    width: 100% !important;
    margin-left: 2px;
    border-radius: 5px;
  }
 
  .rc-slider-track {
    background-color: #ff6b6b !important;
  }
 
  .rc-slider-handle {
    border: 2px solid #ff6b6b !important;
  }
 
 
}
 
@media (max-width:425px) {
  .global-product-filter .search-result-heading {
    font-size: 20px !important;
    margin-top: 6px;
  }
 
  .global-product-filter .showing-results {
    font-size: 13px !important;
  }
}