.modal-content {
    width: 870px !important;
    justify-content: space-around;
    margin-left: -163px;
}

#date-picker-wrapper,
#multi-select-wrapper {
    position: relative;
}

.ant-picker-dropdown,
.multiselect-dropdown {
    position: absolute !important;
    z-index: 9999;
    top: calc(100% + 10px); /* Adjust as needed */
    left: 0;
}

.ant-modal-content {
    position: relative; /* Ensure AntdModal content is positioned relatively */
}

.ant-modal-body {
    overflow-y: auto; /* Enable vertical scrolling for modal body */
}
.custom-switch-container {
    display: flex;
    align-items: center;
}
.react-switch-bg{
    height: 16px !important;
    width: 35px !important;
}
.react-switch-handle{
    height: 10px !important;
    width: 18px !important;
    margin-top : 2px
}



/* .table tr  td{
 padding: 0.2rem 0.6rem;
}  */