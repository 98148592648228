@font-face {
    font-family: nunito;
    src: url(/public/Assets/fonts/Nunito-Medium.ttf);
}

@font-face {
    font-family: nunitobold;
    src: url(/public/Assets/fonts/Nunito-ExtraBold.ttf);
}

.daily-order .card-body {
    padding: 0rem 1rem 0.3rem !important;
}

.dom-layout-2 {
    background-color: #f1f1f1;
}

.dom-order-heading {
    font-size: 1.1rem !important;
    text-transform: uppercase !important;
    font-family: nunitobold, sans-serif !important;
}

.spanClass {
    font-family: nunito, sans-serif !important;

}

.card .dom-card-header {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 0.75rem 0.75rem 0 0;
    padding: 1.5rem 1rem;

}

.card .dom-card-title {
    color: #181818;
    font-weight: 600;
    font-size: 1.3rem !important;
    font-family: nunitobold, sans-serif !important;
}

.dom-table>:not(caption)>*>* {
    padding: 0.8rem 0.8rem;
}

.dom-table tbody,
.dom-table,
.dom-table td,
.dom-table tfoot,
.dom-table th,
.dom-table thead,
.dom-table tr {
    border: 1px solid;
    border-collapse: collapse !important;
}

.custom-border-color {
    border: 1px solid #212529 !important;
    border-collapse: collapse !important;

}

#inventory_list {
    height: calc(100vh - 55vh);
}

#inventory_list thead th {
    background-color: #131921;
    color: #fff;
    padding: 5px;
}

#inventory_list tbody tr td,
#order_list tbody tr td {
    vertical-align: baseline;
    padding: 5px;
}

#order_list thead tr th {
    padding: 5px;
}

.order-number {
    font-size: 1.5rem !important;
    color: #ffbf00 !important;
    font-weight: 600 !important;
}

.order-number1 {
    font-size: 1.4rem !important;
}

.badge {
    padding: 0.55em 0.75em;
    font-size: 15px !important;
    margin-right: 5px;
    margin-bottom: 5px;

}

.daily-order .carousel-control-prev {
    display: none;
}

.daily-order .carousel-control-next {
    display: none;
}


.daily-order .slide {
    width: 100%;
    border: 0 !important;
    padding: 0;
    margin: 0 !important;
}

.daily-order .slide:hover {
    box-shadow: none;
}

.daily-order .carousel-inner {
    padding: 0;
    margin-top: -26px;
}


.daily-order .carousel-indicators {
    margin-top: -10px;
    position: relative;
    right: 0;
    top: -30px;
    right: 0;
    z-index: 2;
    display: inline-flex;
    /* justify-content: end; */
    padding: 0;
    margin-left: 96%;
    margin-bottom: 0;
    /* margin-left: 15%; */
    list-style: none;
    z-index: 0;
}

.daily-order .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    border-radius: 50%;
}

.daily-order .carousel-indicators [data-bs-target]:last-child {
    margin-right: 0px;
}

.daily-order .carousel-indicators [data-bs-target] {
    background-color: #6c757d !important;
}

.daily-order .carousel-dark .carousel-indicators [data-bs-target].active {
    background-color: #007185 !important;
}

.play-button,
.pause-button {
    padding: 0px 10px;
    font-size: 14px !important;
}

/* .marquee-inner.to-left {
    animation: marqueeTop 35s linear infinite !important;
} */
/* .marquee-inner {
    overflow: hidden;
    white-space: nowrap;
  } */

.order-number-list1 .marquee-inner {
    animation: marquee 80s linear infinite;
}

.order-number-list1 .marquee-inner:hover {
    animation-play-state: paused;
}

.order-list-numder1 .marquee-inner {
    animation: marquee 30s linear infinite;
}

.order-list-numder1 .marquee-inner:hover {
    animation-play-state: paused;
}


@keyframes marquee {
    0% {
        transform: translateY(5%);
    }

    100% {
        transform: translateY(-100%);
    }
}


.order-number-list1 {
    height: calc(100vh - 67vh) !important;
    /* margin-left: 30px; */
    float: left;
    /* width: 94%; */
    overflow-y: scroll;
    /* margin-bottom: 25px; */
}

.order-number-list1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
}

.order-number-list1::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
    scroll-behavior: smooth;
}

.order-number-list1::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px transparent;
    background-color: #555;
}

.order-number-list1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
}

.order-number-list1::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    scroll-behavior: smooth;
}

.order-number-list1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
}

.play-pause-action {
    position: absolute;
    right: 78px;
    cursor: pointer;
    margin-top: -3px;
}

/* .dom-card-header button{
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
} */
.play-pause-action {
    font-size: 14px !important;
    z-index: 3;
}

.dom-table-2 {
    height: calc(100vh - 80vh) !important;
    /* margin-left: 30px; */
    float: left;
    /* width: 94%; */
    overflow-y: scroll;
    /* margin-bottom: 25px; */
}

.dom-table-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
}

.dom-table-2::-webkit-scrollbar {
    width: 22px;
    background-color: transparent;
}

.dom-table-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
    background-color: #555;
}

.dom-table-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
    background-color: transparent;
}

.dom-table-2::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}

.dom-table-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
}