@font-face {
    font-family: Outfit;
    src: url("/public/Assets/fonts/Outfit-Regular.ttf");
}


.Product-image img {
    width: 100%;
    height: 185px;

}

.Product-image {
    position: relative;
}

.Product-discount {
    background-color: #ff6b6b;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;

}

.Product-discount div {

    font-size: 12px !important;
    letter-spacing: 0.05em;
    font-family: Outfit !important;
    color: #fff !important;
    text-align: left;
}

.product:hover {
    
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
}

.Product-price {
    text-decoration-line: line-through;
    font-family: Outfit !important;
    color: #9f9f9f;
    text-align: left;
    font-size: 14px;
}

.discounted-price {
    font-weight: 500;
    font-family: Outfit !important;
    color: #88b04b;
    text-align: left;
}

.Product-name {
    font-size: 15px;
    font-weight: 600;
    font-family: Outfit !important;
    color: #2a2e32;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}


.Product-material {
    font-size: 12px;
    font-weight: 500;
    font-family: Outfit !important;
    color: #5b5b5b;
    text-align: left;
}


.our-dedication-frame .react-multi-carousel-dot-list {
    display: none !important;
}

.our-dedication-frame .react-multi-carousel-item {
    min-height: 370px;
}

.product1 :hover {
    .add-to-cart {
        display: flex;
    }
}

.carousel-arrows-left {

    position: absolute;
    width: 46px;
    height: 46px;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    left: 20px;
    border-radius: 50%;
}

.carousel-arrows-right {

    position: absolute;
    width: 46px;
    height: 46px;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    right: 20px;
    border-radius: 50%;
}




.carousel-arrows-left .fa-chevron-left {
    color: grey;
}

.carousel-arrows-right .fa-chevron-right {
    color: grey;
}

.carousel-arrows-left:hover {
    height: 50px;
    width: 50px;

}

.carousel-arrows-left:hover .fa-chevron-left {
    font-size: 23px;
}

.carousel-arrows-right:hover {
    height: 50px;
    width: 50px;
}

.carousel-arrows-right:hover .fa-chevron-right {
    font-size: 23px;
}

.add-to-cart {
    display: none;
    border-radius: 2px;
    background-color: #ff6b6b;
    color: white;
    border: none;
    font-family: Outfit !important;
    width: 100%;
    font-weight: 200;
}

.Product:hover {
    .add-to-cart {
        display: block !important;
    }

    border: none !important;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.10);

}

.discounts-offers1 {
    font-size: 22px;
    font-family: Outfit !important;
    font-weight: 600;
}
@media (max-width: 424px){
    .react-multi-carousel-item .Product{
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
}

@media (max-width: 916px){
    .add-to-cart{
      display: block !important;
  }
}