@font-face {
    font-family: Outfit;
    src: url(/public/Assets/fonts/Outfit-Regular.ttf);
}

.Decor-Item-1 {
    background-image: url("/public/Assets/img/Decor/offer-1.png");
    background-size: cover;
}

.Decor-Item-2 {
    background-image: url("/public/Assets/img/Decor/offer-2.png");
    background-size: cover;
}

.Decor-Item-3 {
    background-image: url("/public/Assets/img/Decor/offer-3.png");
    background-size: cover;
}

.Shop-Now {
    font-size: 15px !important;
    font-family: Outfit !important;
    color: #88b04b;
    text-align: left;
    font-weight: 600;
}

.Shop-Now-Icon {
    background-color: #88b04b;
    border-radius: 50%;
    color: white;
    height: 16px;
    width: 16px;
    padding-left:8px ;
}

.Shop-Now-Icon i {
    font-size: 8px !important;
    margin-left: 2px;
}

.dcor-pots {
    font-size: 15px !important;
    letter-spacing: 0.05em;
    font-family: Outfit !important;
    color: #5b5b5b;
    text-align: left;
}

.grow-your-greenery-container {

    font-size: 20px !important;
    letter-spacing: 0.05em;
    font-weight: 600;
    font-family: Outfit !important;
    color: #2a2e32;
    text-align: left;

}

.payment-delivery {
    font-size: 16px !important;
    letter-spacing: 0.05em;
    line-height: 28px;
    font-weight: 600;
    font-family: Outfit !important;
    color: #2a2e32;
    text-align: left;
}

.in-case-of {
    font-size: 12px !important;
    letter-spacing: 0.01em;
    /* line-height: 28px; */
    font-weight: 500;
    font-family: Outfit !important;
    color: #9f9f9f;
    text-align: left;
    /* white-space: nowrap; */
}
.decor-shop-now{
    cursor: pointer;
}