.form-select {
    border-radius: 0.375rem !important;
}

.card .card-body, .card .card-footer, .card .card-header {
    padding: 1.5rem;
}

.page-nav {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
    .page-nav {
        display: flex;
        justify-content: flex-start;
    }
}