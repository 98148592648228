@font-face {
    font-family: Outfit;
    src: url(/public/Assets/fonts/Outfit-Regular.ttf);
}
.frame-wrapper1 {
    align-self: stretch;
    padding: 0 var(--padding-2xl) var(--padding-smi) var(--padding-xl);
    box-sizing: border-box;
    text-align: center;
  }
  /* .categories-parent,
  .frame-wrapper1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  } */
  .categories1{
    font-family: Outfit !important;
  }
  .categories-parent {
    flex: 1;
    flex-wrap: wrap;
    gap: var(--gap-4xl);
  }
  .categories-parent .slider {
    margin:0 20px;
    overflow:"hidden";
    padding:2rem 0;
  }
  

  .categories-parent .custom-dot-list-style button{
  border: none;
  background: none;
  }
  .categories-parent .react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: none;
  }
  .categories-parent .react-multi-carousel-dot-list{
    display: none !important;
  }
  .categories-parent .react-multiple-carousel__arrow{
    display: none !important;
  }
  
  
  .categories-child {
    width: 6.44rem;
    height: 6.44rem;
    position: relative;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid #88B04B;
    padding: 4px;
  }
  .categories1 {
    font-weight: 500;
    display: inline-block;
    font-size: 15px;
    color: #2a2e32!important;
  }
  
  .categories {
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-4xs);
    min-width: 8.25rem;
    max-width: 8.44rem;
  }
  
  
  .categories,
  .categories-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  .categories-wrapper {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0.63rem 0 var(--padding-mini);
  }
  .categories img:hover{
    box-shadow: 0 0 10px #88B04B ;
  }